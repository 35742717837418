<template>
  <div class="q-pa-sm">
    <q-table
      style="height: 90vh;"
      row-key="id"
      :title="options.title"
      :rows="items"
      :columns="columns"
      v-model:pagination="pagination"
      :loading="isLoading"
      :filter="filter"
      virtual-scroll
      binary-state-sort
      @request="onRequest"
    >
      <template v-slot:top>
        <div class="row border-bottom full-width q-pb-sm">
          <h5 class="q-my-none">
            {{ options.title }}
          </h5>

          <q-space />

          <q-btn
            :color="serverParams.filter && serverParams.filter.length > 0 ? 'light-blue-9' : 'dark'"
            text-color="white"
            size="sm"
            class="q-mr-sm"
            :label="filterBtnText"
            no-caps
            unelevated
            @click="openCloseFilters"
          />

          <q-btn
            color="dark"
            text-color="white"
            :label="$t('Refresh')"
            size="sm"
            class="q-mr-sm"
            no-caps
            unelevated
            @click="refreshItems"
          />
        </div>

        <div class="full-width">
          <filter-collapse
            :is-open="isOpenFilter"
            :options="{
              defaultFilter: serverParams.filter,
              fields: activatedFields,
              services: {
                queue: options.queue
              },
              values: {
                states: statuses
              },
              style: {
                noGroups: true
              }
            }"
            @submit="handleFiltersSubmit"
            @close="openCloseFilters"
          />
        </div>
      </template>

      <template
        v-slot:body="props"
        class="clickable"
      >
        <task-row
          :data="props"
          :service="options.service"
          @dblclick="onRowDblClick(props.row)"
          @submit="upsertItem"
        />
      </template>
    </q-table>

    <task-modal
      ref="taskModal"
      @submit="upsertItem"
    />
  </div>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex'

// Components
import FilterCollapse from '../../components/filters/FilterCollapse.vue'
import TaskModal from '../modals/TaskModal.vue'
import TaskRow from './TaskRow.vue'

// Mixins
import TableMixin from './../../components/global/TableMixin'

// Utils
import { buildQuery } from './../../utils/query-utils'

export default {
  name: 'TasksTable',
  components: {
    FilterCollapse,
    TaskModal,
    TaskRow
  },
  mixins: [
    TableMixin
  ],
  props: {
    options: {
      type: Object,
      default () {
        return {
          title: '',
          service: null
        }
      }
    }
  },
  data () {
    return {
      isOpenFilter: false,
      filter: '',
      items: [],
      isLoading: false,
      pagination: {
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      statuses: [
        { id: 'new', title: 'New' },
        { id: 'confirmed', title: 'Confirmed' },
        { id: 'complete', title: 'Complete' },
        { id: 'rejected', title: 'Rejected' }
      ],
      activatedFields: [
        'id',
        'state',
        'executive',
        'queue',
        ...(this.options.fields || [])
      ],
      totalNumber: 0
    }
  },
  computed: {
    ...mapGetters([
      'appOptions'
    ]),
    columns () {
      return [
        {
          label: this.$t('Id'),
          name: 'id',
          align: 'left'
        },
        {
          label: this.$t('Status'),
          name: 'state',
          align: 'left'
        },
        {
          label: this.$t('Queue'),
          name: 'queue',
          align: 'left'
        },
        {
          label: this.$t('Store'),
          name: 'shop',
          align: 'left'
        },
        {
          label: this.$t('Product'),
          name: 'offer',
          align: 'left'
        },
        {
          label: this.$t('Order'),
          name: 'order',
          align: 'left'
        },
        {
          label: this.$t('Location'),
          name: 'place',
          align: 'left'
        },
        {
          label: this.$t('Worker'),
          name: 'executive',
          align: 'left'
        },
        {
          label: this.$t('Created'),
          name: 'created',
          align: 'left'
        }
      ]
    },
    filterBtnText () {
      return this.serverParams.filter && this.serverParams.filter.length > 0
        ? this.$t('Filtered: ') + this.totalNumber
        : this.$t('Filter')
    }
  },
  mounted () {
    this.loadDefaultItems()
  },
  methods: {
    refreshItems () {
      return this.onRequest({
        pagination: {
          forceReload: true
        }
      })
    },
    upsertItem (item) {
      let hasItem = false

      this.items = this.items.map(x => {
        if (x.id === item.id) {
          hasItem = true
          return item
        }

        return x
      })

      if (!hasItem) {
        this.items = [item, ...this.items]
      }

      return this.items
    },
    handleFiltersSubmit (filter) {
      return this.onRequest({ pagination: { filter, page: 1, per_page: 25 } })
    },
    openCloseFilters () {
      this.isOpenFilter = !this.isOpenFilter
    },
    loadDefaultItems () {
      return this.onRequest({ pagination: {} })
    },
    onRequest (data = {}) {
      this.isLoading = true
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)

      if (!query.filter) {
        query.filter = []
      }

      this.updateParams(query)

      return this.options.service.getAll(this.serverParams)
        .then(({ page, totalItems, items }) => {
          this.pagination = {
            ...this.pagination,
            page,
            rowsNumber: totalItems
          }

          this.totalNumber = totalItems
          this.items = items
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    onRowDblClick (item) {
      this.$refs.taskModal.open(this.options.service, { executive: item._embedded.executive, id: item.id })
    }
  }
}
</script>
